import { RootState } from 'app/root-state'
import { initialState } from '../Model'
import { createSelector } from '@reduxjs/toolkit'

const selectDomain = (state: RootState) => state.marketData || initialState

export const selectMarketDataPage = createSelector(
  [selectDomain],
  marketDataState => marketDataState.MarketDataPage
)
export const selectMarketDataTermsAccepted = createSelector(
  [selectDomain],
  marketDataState => marketDataState.MarketDataTermsAccepted
)
export const selectPaymentMethod = createSelector(
  [selectDomain],
  marketDataState => marketDataState.PaymentMethod
)
export const selectUserCertification = createSelector(
  [selectDomain],
  marketDataState => marketDataState.UserCertified
)
export const selectUserCertificationFetchStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.UserCertifiedFetchStatus
)

// ------------------------- Market Data -----------------------------------------
export const selectExchangePageConfig = createSelector(
  [selectDomain],
  marketDataState => marketDataState.ExchangePageConfig
)
export const selectExchangePageConfigStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.ExchangePageConfigStatus
)
export const selectInitialMarketData = createSelector(
  [selectDomain],
  marketDataState => marketDataState.InitialMarketData
)
export const selectUpdatedMarketData = createSelector(
  [selectDomain],
  marketDataState => marketDataState.updatedCostData
)
export const selectSaveMarketDataEntitlementsPostStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.SaveMarketDataEntitlementsPostStatus
)
export const selectSaveMarketDataEntitlements = createSelector(
  [selectDomain],
  marketDataState => marketDataState.SaveMarketDataEntitlementsRes
)
export const selectSaveMarketDataEntitlementsResponseMessage = createSelector(
  [selectDomain],
  marketDataState => marketDataState.SaveMarketDataEntitlementsResponseMessage
)
export const selectUnsubscibeEntitlementsState = createSelector(
  [selectDomain],
  marketDataState => marketDataState.UnsubscibeEntitlements
)
export const selectUnsubscibeEntitlementsPostStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.UnsubscibeEntitlementsStatus
)
// -------------------------/End Market Data -----------------------------------------

export const selectFuturesAccounts = createSelector(
  [selectDomain],
  marketDataState => marketDataState.FuturesAccounts
)
export const selectFuturesAccountsFetchStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.FuturesAccountsFetchStatus
)
export const selectFutureAccountChoice = createSelector(
  [selectDomain],
  marketDataState => marketDataState.FutureAccountSelected
)

// ------------------------- App State Helpers -----------------------------------------

export const selectGridUserSelections = createSelector(
  [selectDomain],
  marketDataState => marketDataState.GridUpdateSelections
)
export const selectInitialUserSelections = createSelector(
  [selectDomain],
  marketDataState => marketDataState.GridInitialSelections
)
export const selectGridMatchOriginal = createSelector(
  [selectDomain],
  marketDataState => marketDataState.GridMatchOriginal
)
export const selectInitialCostInfo = createSelector(
  [selectDomain],
  marketDataState => marketDataState.initialCostData
)
export const selectGridIsReadOnly = createSelector(
  [selectDomain],
  marketDataState => marketDataState.IsReadOnly
)
export const selectIsLastThreeDayOrLess = createSelector(
  [selectDomain],
  marketDataState => marketDataState.IsLastThreeDayOrLess
)
//  -------------------------/End App State Helpers -----------------------------------------

//  ------------------------- Credit Card Billing -----------------------------------------
export const selectCreditCardCurrentBillingStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardCurrentBillingStatus
)
export const selectCreditCardCurrentBilling = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardCurrentBilling
)
export const selectCreditCardCountries = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardCountries
)
export const selectCreditCardCountriesStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardCountriesStatus
)
export const selectCreditCardStates = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardStates
)
export const selectCreditCardStatesStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardStatesStatus
)
export const selectCreditCardSaveBillingStatus = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardSaveBillingStatus
)
export const selectCreditCardSaveBillingRes = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardSaveBilling
)
export const selectCreditCardWasUpdated = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardWasUpdated
)
export const selectSendCCEmailReceipt = createSelector(
  [selectDomain],
  marketDataState => marketDataState.CreditCardEmailReceipt
)
// -------------------------/End Credit Card Billing -----------------------------------------

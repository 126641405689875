import { Country, State } from 'app/shared/models/models'
import {
  ADDRESS,
  CARD_CVV,
  CARD_EXPIRATION_DATE,
  CARD_TYPE,
  CITY,
  COUNTRY,
  COUNTRY_ID,
  CREDIT_CARD_NUMBER,
  FIRST_NAME,
  LAST_NAME,
  STATE_REGION_PROVINCE,
  STATE_REGION_PROVINCE_ID,
  ZIP_POSTAL_CODE,
} from '../Helpers/constants'
import { UserAccounts } from 'app/components/SoftwareSubscriptions/Model'

export enum MarketDataFetchStatus {
  Initial,
  Loading,
  Success,
  Error,
}

export enum ErrorType {
  ResponseError,
  NotFound,
  StatusError,
  GeneralError,
}

export enum LicenseType {
  nonSelected = 0,
  TopOfBook = 1,
  DepthOfMarket = 2,
  Professional = 3,
  TopAndDepthBundledSix = 6,
  TopAndDepthBundledSeven = 7,
  TopAndDepthBundledNine = 9,
  AllTraders = 8,
  CFETopOfBook = 10,
  CFEMarketDepth = 11,
  CFEProTopofBook = 12,
  CFEProMarketDepth = 13,
}

export enum UserCertType {
  NonProfessional = 10,
  Professional = 20,
  NotSelected = 30,
}
export enum UnsubscibeEntitlementsState {
  Unavalible = 1,
  Avalible = 2,
  Initiated = 3,
  Processing = 4,
  Complete = 5,
}
export enum UserPaymentChoice {
  NonSelected = 0,
  FuturesAccount = 1,
  IbPayout = 2,
  CreditCard = 3,
}

export enum MarketDataPage {
  CertOrExchange,
  PaymentSelection,
  CCSubmitPayment,
  CCPaymentResponse,
  FuturesAccountPayment,
  FuturesAccountResponse,
}

export interface KeyForString {
  id: number
  name: string
  translationKey: string
}

export interface UserCertifiedResponse {
  userCert: UserCertType
}

export interface UserCertifiedRequest {
  userCertChoice: UserCertType
}

export interface UserCertifiedUpdateResponse {
  response: boolean
}

export interface ExchangePageResponse {
  data: ExchangePage
}

export interface UserAccountsResponse {
  status: number
  data: UserAccounts[]
}
export interface SaveMarketDataEntitlementsResponse {
  status: number
  data: SaveMarketDataEntitlementsRes
}

export interface SaveMarketDataEntitlementsResponseError {
  errorType: ErrorType
  response: SaveMarketDataEntitlementsRes | null
}

export interface CurrentBillingResponse {
  status: number
  data: MarketDataCurrentBilling
}

export interface SaveBillingResponse {
  status: number
  data: MarketDataSaveBillingRes
}

export interface MarketDataSaveBillingRes {
  isSuccess: boolean
  billCCId: number
  isAuthorized: string
  error: {
    errorCode: string
    errorDescription: string
  }
}

export interface MarketDataSaveBillingErrorRes {
  error: ErrorType
  response: MarketDataSaveBillingRes | null
}

export interface ShouldBundleHelper {
  licenseNum: number
  numberOfChecked: number
  priceBenchmark: number
  rowPrice: number
  checkedBenchmark: number
  shouldBundle: boolean
  isProfessional: boolean
}

export interface InitialPriceSetHelper {
  licenseNum: number
  entitledNumBenchmark: number
  highestEntitledExchPrice: number
  entitledPriceSum: number
  entitledExchIds: number[]
  defaultRowPrice: number
  defualtBundlePrice: number
  numOfEntitled: number
  isProfessional: boolean
}

export interface MarketDataSaveBillingBody {
  firstName: string
  lastName: string
  address: string
  city: string
  stateId: number
  zip: string
  countryId: number
  number?: string
  expDate?: string
  code?: string
  agreeArb: boolean
  agreeTerms: boolean
  amount: number
  creditCardUpdated: boolean
  customerId: number | null
}

export interface MarketDataCurrentBilling {
  firstName: string
  lastName: string
  address: string
  city: string
  stateId: number
  zip: string
  countryId: number
  number: string
  expDate: string
  code: string
  agreeArb: boolean
  agreeTerms: boolean
  id: number
  active: boolean
  hasProfile: boolean
  cardType: string
}

export interface MarketDataCardDetails {
  id: number
  [FIRST_NAME]: string
  [LAST_NAME]: string
  [ADDRESS]: string
  email: string
  [CITY]: string
  [STATE_REGION_PROVINCE_ID]: number
  [STATE_REGION_PROVINCE]: string
  [ZIP_POSTAL_CODE]: string
  [COUNTRY_ID]: number
  [COUNTRY]: string
  [CREDIT_CARD_NUMBER]: string
  [CARD_EXPIRATION_DATE]: string
  [CARD_CVV]: string
  [CARD_TYPE]: string
  hasProfile: boolean
  creditCardUpdated: boolean
}

export interface PriceSumHelper {
  bundlePrice?: number
  licenseId?: number
  summedPrice?: number
  ccFee?: number
  adminFee?: number
  todayDataFee?: number
  todayConvienceFee?: number
  todayAdminFee?: number
  todayCharge?: number
  monthlyAdminFee?: number
  monthlyCost?: number
}

export interface GridProvider {
  gridId: number
  providerId?: number
  providerName?: string
  providerDescription?: string
  hasEntitlements: boolean
  gridEntitlements?: GridEntitlementsRows[]
  hasSoftware: boolean
  softwareInfo?: GridSoftware[]
  licenseCols: LicenseType[]
}

export interface GridSoftware extends EntitlementSoftware {
  rawEntitlements?: Entitlement[]
  gridEntitlements?: GridEntitlementsRows[]
  licenseCols?: LicenseType[]
}

export interface GridEntitlementsRows {
  rowId: number
  providerId: number
  gridCheckboxValue: LicenseType
  gridBundleValue: LicenseType
  isProfessional: boolean
  renewable: boolean
  isBundleRow: boolean
  exchangeName: string
  exchangeDesc: string
  exchangeId: number
  softwareId?: number | null
  exchange?: EntitlementExchange
  rowData?: GridEntitlementKeyMap[]
}

export interface GridEntitlementKeyMap {
  providerId: number
  exchangeId: number
  licenseId: number
  softwareId: number | null
  softwareAppTitle?: string | null
  price: number
  adjustedPrice?: number
  licenseName?: string
  licenseDescription?: string
  exchangeName?: string
  exchangeDesc?: string
  bundlePrice: number
  adjustedBundlePrice?: number
  entitled: boolean
  renewable: boolean
  shouldCharge: boolean
}

export interface SaveMarketDataEntitlementsBody {
  customerId: number
  isManaging: boolean
  acceptedIceAgreement: boolean
  acceptedCmeNonProAgreement: boolean
  paymentType: number
  accountToPayFrom: string
  sendCCReceipt: boolean
  dataProviders: DataProvider[]
}
export interface DataFeedEntitlementScope {
  id?: string
  entitlementID?: string
  subscriptionType?: string
  cashAdjustmentID?: string
  chargedAmount?: string
  exchangeName?: string
  renewable?: string
  expirationDate?: string
  softwareName?: string
  timestamp?: string
  sentToUbixTimestamp: {
    xsi?: string
  }
}
export interface MarketDataResultsObj {
  ok: boolean
  errorText: string | null
  dataFeedEntitlementScopes: {
    dataFeedEntitlementScope: DataFeedEntitlementScope[]
  }
  dataFeedEntitlement: {
    id?: string
    userName?: string
    professionalStatus?: string
    entitlementType?: string
    tradeMonth?: string
    renewalPrice?: string
    timestamp?: string
    accountSpec?: string
  }
}

export interface MarketDataTransactionDetails {
  id: number
  dataTransactionId: number
  dataLicenseId: number
  dataExchangeId: number
  softwareName: string
  charge: number
  action: boolean
}

export interface SaveMarketDataEntitlementsRes {
  id: number
  adminId: number
  username: string
  prodAccountNumber: string
  simAccountNumber: string | null
  newCharge: number
  monthlyCharge: number
  charged: boolean
  comment: string | null
  currentDataPlanTypeId: number | null
  isCCPayment: boolean
  ccMaskedNbr: string | null
  ccExpDate: string | null
  ccType: string | null
  isBroker: boolean
  returnEntitlementsMessage: string
  entitlements: null
  ccResponse: {
    isSuccess: boolean
    transactionId: string | null
    transactionCode: string | null
    invoiceNumber: string | null
    amountCharged: number | null
    error?: {
      errorCode: string
      errorMessage: string
    }
  }
  prodResult: null
  prodResults: MarketDataResultsObj[] | null
  simResult: null
  simResults: MarketDataResultsObj[] | null
  transaction: {
    id: number
    dataPlanTypeId: number
    customerOfId: number | null
    brokerId: number | null
    timestamp: string
    totalCharge: number
    adminUserId: number | null
    comment: string | null
    details: MarketDataTransactionDetails[]
  } | null
  linkedResults: [] | null
}

// Root Lvl 0
export interface ExchangePage {
  whitelabelId?: number
  currentDataPlanId?: number
  userName?: string
  customerId?: number
  isManaging?: boolean
  professional?: boolean
  legacy?: boolean
  live?: boolean
  adminFee: number
  ccFee: number
  dataProviders?: DataProvider[]
  hasActiveDataPlanWithAccount: boolean
  hasActiveDataPlanWithCC: boolean
  dataLinkItems?: []
  dataLinks?: []
  email?: string
  failure?: boolean
  netting?: boolean
  prodAccountNumber?: string
  simAccountNumber?: string
  apiAccountNumber?: string
  isLastThreeDayOrLess: boolean
}
// Lvl 1
export interface DataProvider {
  api?: boolean
  description?: string
  entitlements?: Entitlement[]
  id?: number
  name?: string
  proLegacyDiscount?: boolean
  prod?: boolean
  professional?: boolean
  sim?: boolean
  subscriptionType?: number
}

// Lvl 2
export interface Entitlement {
  license?: EntitlementLicense | null
  exchange?: EntitlementExchange | null
  software?: EntitlementSoftware | null
  price?: number
  entitled?: boolean | null
  renewable?: boolean | null
  shouldCharge?: boolean | null
}

// Lvl 3
export interface EntitlementExchange {
  id?: number
  name?: string
  oecName?: string
  description?: string
  active?: boolean
  dataExchangeMappings?: []
}
// Lvl 3
export interface EntitlementLicense {
  id?: number
  name?: string
  description?: string
  level?: number
  bundlePrice?: number
  subscriptionType?: number
  professional?: boolean
  active?: boolean
  hasBundle?: boolean
  bundleSelected?: boolean
  providerId?: number
}
// Lvl 3
export interface EntitlementSoftware {
  id: number
  appTitle?: string
  keytoken?: string
  enabled?: boolean | null
  description?: string
  uuid?: string
  companyName?: string
  contactPerson?: string
  contactEmail?: string
  isATS?: boolean | null
  atsState?: string
  atsCountryCode?: string
  transactionFee?: number
  defaultTransactionFee?: number
  charge?: boolean | null
  selected?: boolean | null
  prodName?: string
  children?: EntitlementSoftwareChildren[]
}
// Lvl 4
export interface EntitlementSoftwareChildren {
  id?: number
  appTitle?: string
  keytoken?: string
  enabled?: boolean
  description?: string
  uuid?: string
  companyName?: string
  contactPerson?: string
  contactEmail?: string
  isATS?: boolean
  atsState?: string
  atsCountryCode?: string
  transactionFee?: number
  defaultTransactionFee?: number
  charge?: boolean
  selected?: boolean
  prodName?: string
  children?: []
}

//-------------------------------State--------------------------
export interface MarketDataState {
  IsReadOnly: boolean
  IsLastThreeDayOrLess: boolean
  MarketDataPage: MarketDataPage
  MarketDataTermsAccepted: boolean
  PaymentMethod: UserPaymentChoice
  GridInitialSelections: GridEntitlementKeyMap[]
  GridUpdateSelections: GridEntitlementKeyMap[]
  GridMatchOriginal: boolean
  UserCertifiedFetchStatus: MarketDataFetchStatus
  UserCertified: UserCertType
  UserCertifiedError: ErrorType | null
  UnsubscibeEntitlements: UnsubscibeEntitlementsState
  UnsubscibeEntitlementsStatus: MarketDataFetchStatus
  ExchangePageConfig: GridProvider[] | null
  ExchangePageConfigStatus: MarketDataFetchStatus
  ExchangePageConfigError: ErrorType | null
  FuturesAccounts: UserAccounts[]
  FutureAccountSelected: UserAccounts | null
  FuturesAccountsFetchStatus: MarketDataFetchStatus
  FuturesAccountsError: ErrorType | null
  InitialMarketData: ExchangePage | null
  SaveMarketDataEntitlementsRes: SaveMarketDataEntitlementsRes | null
  SaveMarketDataEntitlementsResponseMessage: string[]
  SaveMarketDataEntitlementsPostStatus: MarketDataFetchStatus
  SaveMarketDataEntitlementsError: ErrorType | null
  CreditCardCurrentBilling: MarketDataCardDetails | null
  CreditCardCurrentBillingStatus: MarketDataFetchStatus
  CreditCardCurrentBillingError: ErrorType | null
  CreditCardSaveBilling: MarketDataSaveBillingRes | null
  CreditCardSaveBillingStatus: MarketDataFetchStatus
  CreditCardSaveBillingError: ErrorType | null
  CreditCardWasUpdated: boolean
  CreditCardCountries: Country[]
  CreditCardCountriesStatus: MarketDataFetchStatus
  CreditCardCountriesError: ErrorType | null
  CreditCardStates: State[]
  CreditCardStatesStatus: MarketDataFetchStatus
  CreditCardStatesError: ErrorType | null
  CreditCardEmailReceipt: boolean
  initialCostData: PriceSumHelper | null
  updatedCostData: PriceSumHelper | null
}

export const initialState: MarketDataState = {
  IsReadOnly: false,
  IsLastThreeDayOrLess: false,
  MarketDataPage: MarketDataPage.CertOrExchange,
  MarketDataTermsAccepted: false,
  PaymentMethod: UserPaymentChoice.NonSelected,
  GridInitialSelections: [],
  GridUpdateSelections: [],
  GridMatchOriginal: true,
  UserCertified: UserCertType.NotSelected,
  UserCertifiedFetchStatus: MarketDataFetchStatus.Initial,
  UserCertifiedError: null,
  UnsubscibeEntitlements: UnsubscibeEntitlementsState.Unavalible,
  UnsubscibeEntitlementsStatus: MarketDataFetchStatus.Initial,
  ExchangePageConfig: null,
  ExchangePageConfigStatus: MarketDataFetchStatus.Initial,
  ExchangePageConfigError: null,
  InitialMarketData: null,
  SaveMarketDataEntitlementsRes: null,
  SaveMarketDataEntitlementsResponseMessage: [],
  SaveMarketDataEntitlementsPostStatus: MarketDataFetchStatus.Initial,
  SaveMarketDataEntitlementsError: null,
  FuturesAccounts: [],
  FutureAccountSelected: null,
  FuturesAccountsFetchStatus: MarketDataFetchStatus.Initial,
  FuturesAccountsError: null,
  CreditCardCurrentBilling: null,
  CreditCardCurrentBillingStatus: MarketDataFetchStatus.Initial,
  CreditCardCurrentBillingError: null,
  CreditCardSaveBilling: null,
  CreditCardSaveBillingStatus: MarketDataFetchStatus.Initial,
  CreditCardSaveBillingError: null,
  CreditCardWasUpdated: false,
  CreditCardCountries: [],
  CreditCardCountriesStatus: MarketDataFetchStatus.Initial,
  CreditCardCountriesError: null,
  CreditCardStates: [],
  CreditCardStatesStatus: MarketDataFetchStatus.Initial,
  CreditCardStatesError: null,
  CreditCardEmailReceipt: false,
  initialCostData: null,
  updatedCostData: null,
}

import { createSelector } from '@reduxjs/toolkit'
import { RootState } from '../../../root-state'
import { initialState } from '../Model/'

const selectDomain = (state: RootState) => state.subscriptions || initialState

export const selectTermsResponse = createSelector([selectDomain], data => data.termsModal)
export const selectSubscriptionData = createSelector([selectDomain], data => data.subscriptionsData)
export const subscriptionWarning = createSelector([selectDomain], data => data.subscriptionWarning)
export const storedChangedSubscriptionData = createSelector(
  [selectDomain],
  data => data.storedChangedSubscriptionData
)
export const selectUserAccountsData = createSelector([selectDomain], data => data.userAccounts)
export const selectUserAccountsDataFetchStatus = createSelector(
  [selectDomain],
  data => data.userAccountFetchStatus
)
export const selectSubscriptionError = createSelector(
  [selectDomain],
  data => data.subscriptionError
)
export const getChangeSubscriptionsPage = createSelector(
  [selectDomain],
  data => data.showChangeSubscriptionsPage
)
export const selectChangeSubscriptionDetails = createSelector(
  [selectDomain],
  data => data.newSubscriptionDataObj
)
export const getProcessingStatus = createSelector(
  [selectDomain],
  data => data.subscriptionProcessingStatus
)

import { call, put, takeLatest } from 'redux-saga/effects'
import {
  GetAccessTypeAPI,
  GetThirdPartySubsAPI,
  PostEnableThirdPartyAPI,
} from '../Services/thirdParty.service'
import { thirdPartyActions as actions } from './thirdparty.slice'
import {
  EnableThirdPartyRequest,
  SoftwareSubSettings,
  ThirdPartyAccessType,
  ThirdPartyData,
} from '../Model'
import { PayloadAction } from '@reduxjs/toolkit'
import { translate } from 'app/shared/localization/helper/localization-helper'

export function* GetAccessType() {
  try {
    const data: ThirdPartyAccessType = yield call(GetAccessTypeAPI)
    if (data !== null) {
      yield put(actions.accessTypeLoaded(data))
    } else {
      yield put(actions.accessTypeError(data))
    }
  } catch (err) {
    yield put(actions.accessTypeError(null))
  }
}

export function* GetThirdPartySubscriptions(id: PayloadAction<number | null>) {
  try {
    const data: ThirdPartyData = yield call(GetThirdPartySubsAPI, id.payload)
    if (data !== null) {
      yield put(actions.thirdPartySubscriptionsLoaded(data))
    } else {
      yield put(
        actions.thirdPartySubscriptionsError(
          translate(
            't__ThirdPartyError',
            'An error occurred pulling third party software',
            'Messages'
          )
        )
      )
    }
  } catch (err) {
    if (err.toString().indexOf('403', 0) > 0) {
      yield put(
        actions.thirdPartySubscriptionsError(
          translate(
            't__ThirdPartyAccessError',
            'You do not have access to third party software downloads',
            'Messages'
          )
        )
      )
    } else {
      yield put(
        actions.thirdPartySubscriptionsError(
          translate(
            't__ThirdPartyError',
            'An error occurred pulling third party software',
            'Messages'
          )
        )
      )
    }
  }
}

export function* EnableThirdParty(action: PayloadAction<EnableThirdPartyRequest>) {
  try {
    const postResponse: SoftwareSubSettings = yield call(PostEnableThirdPartyAPI, action.payload)

    if (postResponse.success === true) {
      yield put(actions.enableThirdPartyCompleted(postResponse))
    } else {
      yield put(actions.enableThirdPartyError(postResponse))
    }
  } catch (err) {
    yield put(actions.enableThirdPartyError(null))
  }
}

export function* thirdPartySaga() {
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(actions.getAccessType.type, GetAccessType)
  yield takeLatest(actions.getThirdPartySubscriptions.type, GetThirdPartySubscriptions)
  yield takeLatest(actions.enableThirdParty.type, EnableThirdParty)
}
